// look for a path matching /e/some-identifier
let emailResult = window.location.pathname.match(/^\/e\/([^\/]+)/);

if (emailResult != null) {
  followAndRedirect();
}

// look for a path matching /t/some-identifier
let textMessageResult = window.location.pathname.match(/^\/t\/([^\/]+)/);

if (textMessageResult != null) {
  followAndRedirect();
}

function followAndRedirect() {
  // make a request to /x/some-identifier/follow to record the visit,
  // then redirect to the root
  fetch(window.location.pathname + "/follow").then((_) => {
    window.location.pathname = "/";
  });
}
