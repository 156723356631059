let audioPlayers = Array.from(document.querySelectorAll(".audio-player"));

audioPlayers.forEach((player) => {
  let audioId = player.dataset.audioId;
  let audio = document.getElementById("audio-" + audioId);
  let playButton = player.getElementsByClassName("play-button")[0];
  let playResolved = true;

  function displayEndedNotice() {
    document.getElementById("sr-notification").innerHTML =
      '<span class="sr-only">audio ended</span>';
  }

  function formatSeconds(seconds) {
    return new Date(1000 * seconds).toISOString().substr(14, 5);
  }

  playButton.addEventListener("click", (event) => {
    if (audio.paused) {
      playResolved = false;
      audio
        .play()
        .then(function () {
          playResolved = true;
        })
        .catch((error) => {
          if (error.name !== "AbortError") {
            throw error;
          }
        });
    } else if (playResolved) {
      audio.pause();
    }
  });

  audio.addEventListener("play", function () {
    playButton.innerHTML = '<i class="fas fa-pause"></i> Pause';
  });

  audio.addEventListener("pause", function () {
    playButton.innerHTML = '<i class="fas fa-play"></i> Play';
  });

  audio.addEventListener("ended", function () {
    displayEndedNotice();
  });

  audio.addEventListener("loadedmetadata", function () {
    // sanity check for reasonable value
    let duration = audio.duration > 10000 ? 0 : audio.duration;
    player.getElementsByClassName("total-length")[0].innerHTML =
      formatSeconds(duration);
  });

  audio.addEventListener("timeupdate", function () {
    player.getElementsByClassName("current-time")[0].innerHTML = formatSeconds(
      audio.currentTime
    );
    // prevent divide by 0 error
    let duration =
      audio.duration == null || audio.duration == 0 ? 0.1 : audio.duration;
    let percentComplete = Math.round((audio.currentTime / duration) * 100);
    player.getElementsByClassName("progress-bar")[0].style.width =
      percentComplete + "%";
  });
});
