import * as Sentry from "@sentry/browser";
import { Integrations as TracingIntegrations } from "@sentry/tracing";

let bodyData = document.querySelector("body").dataset;

if (["staging", "production"].includes(bodyData["environment"])) {
  Sentry.init({
    dsn: bodyData["dsn"],
    environment: bodyData["environment"],
    release: "kiu3 " + bodyData["release"],
    ignoreErrors: ["ResizeObserver loop limit exceeded"],
    integrations: [
      new TracingIntegrations.BrowserTracing({
        tracingOrigins: [
          "kiu-30.isgmh-dev.northwestern.edu",
          "ikeepitup.isgmh.northwestern.edu"
        ]
      })
    ],
    tracesSampleRate: 0.5
  });

  Sentry.configureScope((scope) => {
    scope.setUser({ id: bodyData["id"] });
    scope.setTag("referrer", document.referrer);
  });
}
