$(document).ready(function () {
  function setSelect() {
    // Ensures selectpicker function is loaded and attached to jQuery

    if (typeof $("select").selectpicker === "function") {
      $.fn.selectpicker.Constructor.BootstrapVersion = "4.4.1";
      if ($("select").length) {
        $("select").selectpicker({ size: 4 });
        return;
      }
    } else {
      setTimeout(setSelect, 25);
    }
  }

  setTimeout(setSelect, 500);
});
