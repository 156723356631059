import RailsAjax from "../../shared/rails-ajax";
import * as Sentry from "@sentry/browser";

let bodyData = document.querySelector("body").dataset;
let environments = ["development", "staging", "test", "production"];
let environmentInitialized = environments.includes(bodyData.environment);

if (environmentInitialized && bodyData.pageLoadTimeUpdatePath != null) {
  // these must be initialized here since they're used in multiple scopes below
  let domLoadTime, domLoadedTime;

  window.addEventListener("DOMContentLoaded", () => {
    domLoadedTime = new Date().getTime();
    domLoadTime = domLoadedTime - performance.timing.responseStart;
  });

  window.addEventListener("load", () => {
    let windowLoadedTime = new Date().getTime();
    let windowLoadTime = windowLoadedTime - domLoadedTime;
    let timing = performance.timing;
    let networkAndAppInMs = timing.responseStart - timing.navigationStart;

    RailsAjax.update(bodyData.pageLoadTimeUpdatePath, {
      server_request_started_at: bodyData.requestStartedAt,
      dom_in_ms: domLoadTime,
      window_in_ms: windowLoadTime,
      network_and_app_in_ms: networkAndAppInMs
    });
  });
} else if (bodyData.id) {
  Sentry.captureMessage(
    "unable to find body environment data: " + JSON.stringify(bodyData)
  );
}
