import markerIcon from "./marker-icon.png";

L.Icon.Default = L.Icon.Default.extend({
  _getIconUrl: function (name) {
    var paths = { "icon.png": markerIcon };
    return paths[name + ".png"];
  },

  _detectIconPath: function () {
    return "";
  }
});
L.Marker = L.Marker.extend({
  options: {
    icon: new L.Icon.Default()
  }
});

L.marker = function (latlng, options) {
  return new L.Marker(latlng, options);
};
