import Rails from "@rails/ujs";

const stateSelector = document.getElementById(
  "dtc_pre_screen_self_reported_state"
);

if (stateSelector != null) {
  stateSelector.addEventListener("change", function () {
    Rails.ajax({
      url: "/dtc_pre_screens/counties?state=" + stateSelector.value,
      type: "GET"
    });
  });

  const cookieModal = document.getElementById("cookieModal");
  const cookieAcceptButton = document.getElementById("cookieAcceptButton");
  cookieAcceptButton.addEventListener("click", function () {
    cookieModal.style.display = "none";
  });
}
