import RailsAjax from "../../shared/rails-ajax";

let submitWithDisclaimer = document.getElementById(
  "submit-form-with-disclaimer"
);

if (submitWithDisclaimer) {
  var modal = document.getElementById("modal-form-disclaimer-container");

  submitWithDisclaimer.addEventListener("click", function () {
    modal.style.display = "block";
    document.getElementById("modal-form-disclaimer-title").innerHTML =
      this.dataset.modalTitle;
    document.getElementById("form-disclaimer-accept").innerHTML =
      this.dataset.submitWith;
    document.getElementById("form-disclaimer-decline").innerHTML =
      this.dataset.cancelWith;
    document.getElementById("form-disclaimer-text").innerHTML =
      this.dataset.disclaimerText;
  });

  function createEmailDisclaimerResponse(accepted) {
    RailsAjax.post(modal.dataset.email_disclaimer_response_post_url, {
      email_disclaimer_response: {
        accepted: accepted,
        email_prompt: modal.dataset.controller_name
      }
    });
  }

  function closeModal() {
    modal.style.display = "none";
    createEmailDisclaimerResponse(false);
  }

  document
    .getElementById("form-disclaimer-decline")
    .addEventListener("click", closeModal);
  document.getElementById("modal-close").addEventListener("click", closeModal);
  document
    .getElementById("form-disclaimer-accept")
    .addEventListener("click", function () {
      createEmailDisclaimerResponse(true);
    });
}
