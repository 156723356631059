import Player from "@vimeo/player";
import RailsAjax from "../shared/rails-ajax";

var events = new Array("pause", "play", "ended");
var video = document.getElementById("intro-video");

function sendIntroVideoEvent(kind) {
  RailsAjax.post("/participants/participant_events/intro_video_actions", {
    intro_video_action: {
      kind: kind
    }
  });
}

if (video != null) {
  var player = new Player(video);
  for (let event in events) {
    player.on(events[event], function () {
      sendIntroVideoEvent(events[event]);
    });
  }
}
