import RailsAjax from "../shared/rails-ajax";

let resourceList = document.querySelector("#resources-list");

if (resourceList) {
  let links = Array.from(document.querySelectorAll("#resources-list a"));

  links.forEach(function (link) {
    link.addEventListener("click", function () {
      createResourcePageClick(this.text, this.href);
    });
  });

  function createResourcePageClick(title, url) {
    RailsAjax.post(resourceList.dataset.clicks_post_url, {
      resource_page_click: {
        title: title,
        url: url
      }
    });
  }
}

let env = document.querySelector("body").dataset["environment"];

if (env === "test") {
  Array.from(document.querySelectorAll(".collapse")).forEach((element) => {
    element.classList.add("no-transition");
  });
}
