document.addEventListener("DOMContentLoaded", function () {
  if (!document.getElementById("question_list")) {
    return;
  } else {
    var question_data = {
      0: { 0: 0, 1: 0, 2: 1, 3: 2, 4: 2, 5: 1, 6: 0, 7: 1, 8: 2 },
      1: { 0: 1, 1: 1, 2: 1, 3: 1, 4: 1, 5: 2, 6: 2, 7: 2, 8: 1 },
      2: { 0: 0, 1: 2, 2: 0, 3: 2, 4: 2, 5: 1, 6: 1, 7: 1, 8: 2 },
      3: { 0: 1, 1: 1, 2: 2, 3: 1, 4: 1, 5: 2, 6: 1, 7: 2, 8: 2 },
      4: { 0: 1, 1: 2, 2: 0, 3: 0, 4: 1, 5: 0, 6: 0, 7: 2, 8: 0 },
      5: { 0: 1, 1: 1, 2: 1, 3: 1, 4: 1, 5: 1, 6: 0, 7: 1, 8: 1 },
      6: { 0: 2, 1: 2, 2: 2, 3: 2, 4: 1, 5: 1, 6: 1, 7: 1, 8: 2 }
    };

    var colors = {
      0: "#FF9D98",
      1: "#A6ECB2"
    };

    Array.from(document.querySelectorAll("input")).forEach((input) => {
      input.addEventListener("click", () => {
        for (var i = 0; i <= 8; i++) {
          var value = 1;

          document.querySelectorAll("input").forEach((input2) => {
            if (
              (input2.checked && input2.className !== "no_question") ||
              (!input2.checked && input2.className === "no_question")
            ) {
              value *= question_data[input2.name][i];
            }
          });
          document.getElementById("place_" + i).style.backgroundColor =
            colors[value] || "#FFE680";
        }
      });
    });
  }
});
