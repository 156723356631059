window.invalid_text = "Invalid combination. Try another combination.";
window.hiv_risk_data = {
  Hand: {
    Hand: {
      Null: {
        Null: {
          Null: {
            Null: {
              Value: 0,
              Explain:
                "Touching, like holding hands and cuddling, does not put you at risk for HIV."
            }
          }
        }
      }
    },
    Butt: {
      Null: {
        Null: {
          Null: {
            Null: {
              Value: 0,
              Explain:
                "Fingering your partner's butt poses a VERY LOW risk for HIV."
            }
          }
        }
      }
    },
    Vagina: {
      Null: {
        Null: {
          Null: {
            Null: {
              Value: 0,
              Explain:
                "Fingering your partner's vagina poses a VERY LOW risk for HIV. "
            }
          }
        }
      }
    },
    Penis: {
      Null: {
        Null: {
          Null: {
            Null: {
              Value: 0,
              Explain:
                "Giving your partner a handjob poses a VERY LOW risk for HIV."
            }
          }
        }
      }
    },
    Mouth: {
      Null: {
        Null: {
          Null: {
            Null: {
              Value: 0,
              Explain:
                "Getting your fingers sucked does not put you at risk for HIV."
            }
          }
        }
      }
    }
  },
  Mouth: {
    Mouth: {
      Null: {
        Null: {
          Null: {
            Null: {
              Value: 0,
              Explain: "Kissing does not put you at risk for HIV."
            }
          }
        }
      }
    },
    Butt: {
      Null: {
        Null: {
          Null: {
            Null: {
              Value: 0,
              Explain:
                "Giving your partner a rim job (oral sex on butt) poses a VERY LOW risk for HIV. "
            }
          }
        }
      }
    },
    Vagina: {
      Null: {
        Null: {
          Null: {
            Null: {
              Value: 0,
              Explain:
                "Going down (oral sex) on a vagina poses a VERY LOW risk for HIV. "
            }
          }
        }
      }
    },
    Penis: {
      Null: {
        Null: {
          Null: {
            Null: {
              Value: 0,
              Explain:
                "Giving your partner a blowjob (oral sex on penis) poses a VERY LOW risk for HIV. "
            }
          }
        }
      }
    },
    Hand: {
      Null: {
        Null: {
          Null: {
            Null: {
              Value: 0,
              Explain:
                "Sucking on your partner's fingers does not put you at risk for HIV."
            }
          }
        }
      }
    }
  },
  Penis: {
    Hand: {
      Null: {
        Null: {
          Null: {
            Null: {
              Value: 0,
              Explain: "Getting a handjob poses a VERY LOW risk for HIV."
            }
          }
        }
      }
    },
    Mouth: {
      Null: {
        Null: {
          Null: {
            Null: {
              Value: 0,
              Explain:
                "Getting a blowjob (oral sex on penis) poses a VERY LOW risk for HIV.  "
            }
          }
        }
      }
    },
    Penis: {
      Null: {
        Null: {
          Null: {
            Null: {
              Value: 0,
              Explain:
                "Grinding, or rubbing your penis and your partner's penis together, poses a VERY LOW risk for HIV."
            }
          }
        }
      }
    },
    Vagina: {
      Condom: {
        Prep: {
          Uplus: {
            Null: {
              Value: 0.5,
              Explain:
                "You’re the TOP partner during VAGINAL sex, your partner is undetectable, you take PrEP, and you use condoms. This poses a LOW risk for HIV. Great job! You’re doing everything you can to protect your sexual health! Add or subtract some factors to see how your HIV risk changes."
            },
            Sti: {
              Value: 0.5,
              Explain:
                "You’re the TOP partner during VAGINAL sex, one of you has an STI, your partner is undetectable, you take PrEP, and you use condoms.  This poses a LOW risk for HIV. Great job! You’re doing a lot to protect your sexual health! However, certain STIs can increase HIV risk. Subtract some factors to see how your HIV risk changes."
            }
          },
          Null: {
            Null: {
              Value: 0.5,
              Explain:
                "You’re the TOP partner during VAGINAL sex, your partner has a detectable viral load, you take PrEP, and you use condoms. This poses a LOW risk for HIV. Great job! Using two layers of protection reduces your risk of HIV by a lot! Add or subtract some factors to see how your HIV risk changes."
            },
            Sti: {
              Value: 0.5,
              Explain:
                "You’re the TOP partner during VAGINAL sex, one of you has an STI, your partner has a detectable viral load, you take PrEP, and you use condoms. This poses a LOW risk for HIV. Great job! Using two layers of protection reduces your risk of HIV by a lot! However, certain STIs can increase HIV risk. Add or subtract some factors to see how your HIV risk changes."
            }
          }
        },
        Null: {
          Uplus: {
            Null: {
              Value: 0.5,
              Explain:
                "You’re the TOP partner during VAGINAL sex, your partner is undetectable, and you use condoms. This poses a LOW risk for HIV. Great job! Using condoms and having a partner who is undetectable reduces your risk for HIV by a lot! However, you can't control your partner's viral load. So, use more than one form of protection that IS under your control. "
            },
            Sti: {
              Value: 0.5,
              Explain:
                "You’re the TOP partner during VAGINAL sex, one of you has an STI, your partner is undetectable, and you use condoms. This poses a LOW risk for HIV. Great job! Using condoms and having a partner who is undetectable reduces your risk for HIV by a lot! However, you can’t control your partner’s viral load, and certain STIs can increase HIV risk. So, use more than one form of protection that IS under your control. "
            }
          },
          Null: {
            Null: {
              Value: 1,
              Explain:
                "You’re the TOP partner during VAGINAL sex, your partner has a detectable viral load, and you use condoms. Your partner’s detectable viral load puts you at risk for HIV. But, using condoms reduces your risk by a lot! Adding more forms of protection can further reduce your risk. "
            },
            Sti: {
              Value: 2,
              Explain:
                "You’re the TOP partner during VAGINAL sex, one of you has an STI, your partner has a detectable viral load, and you use condoms. Your partner’s detectable viral load puts you at risk for HIV, and certain STIs can increase that risk. But, using condoms reduces your risk by a lot! Adding more forms of protection can further reduce your risk."
            }
          }
        }
      },
      Null: {
        Prep: {
          Uplus: {
            Null: {
              Value: 0.5,
              Explain:
                "You’re the TOP partner during VAGINAL sex, your partner is undetectable, and you take PrEP. This poses a LOW risk for HIV. Great job! Taking PrEP and having a partner who is undetectable reduces your risk for HIV by a lot! However, you can't control your partner's viral load, So, use more than one form of protection that IS under your control."
            },
            Sti: {
              Value: 0.5,
              Explain:
                "You’re the TOP partner during VAGINAL sex, one of you has an STI, your partner is undetectable, and you take PrEP. This poses a LOW risk for HIV. Great job! Taking PrEP and having a partner who is undetectable reduces your risk for HIV by a lot! However, you can’t control your partner’s viral load, and certain STIs can increase HIV risk. So, use more than one form of protection that IS under your control."
            }
          },
          Null: {
            Null: {
              Value: 0.5,
              Explain:
                "You’re the TOP partner during VAGINAL sex, your partner has a detectable viral load, and you take PrEP. This poses a LOW risk for HIV. Good job taking PrEP! However, your partner’s detectable viral load can still put you at risk for HIV if you miss doses of PrEP. So, use more than one form of protection just in case."
            },
            Sti: {
              Value: 2,
              Explain:
                "You’re the TOP partner during VAGINAL sex, one of you has an STI, your partner has a detectable viral load, and you take PrEP. Good job taking PrEP! Your partner’s detectable viral load puts you at risk for HIV, and certain STIs can increase that risk. But, taking PrEP reduces your risk by a lot! Adding more forms of protection can further reduce your risk."
            }
          }
        },
        Null: {
          Uplus: {
            Null: {
              Value: 0.5,
              Explain:
                "You’re the TOP partner during VAGINAL sex, and your partner is undetectable. This poses a LOW risk for HIV. The medication your HIV-positive partner takes helps keep HIV from spreading. But, you can’t control your partner’s viral load. So, use more than one form of protection that IS under your control."
            },
            Sti: {
              Value: 0.5,
              Explain:
                "You’re the TOP partner during VAGINAL sex, one of you has an STI, and your partner is undetectable. This poses a LOW risk for HIV. The medication your HIV-positive partner takes helps keep HIV from spreading. But, you can't control your partner's viral load, and certain STIs can increase HIV risk. So, use more than one form of protection that IS under your control."
            }
          },
          Null: {
            Null: {
              Value: 4,
              Explain:
                "You’re the TOP partner during VAGINAL sex, your partner has a detectable viral load, and you don’t use protection. Your partner's detectable viral load puts you at risk for HIV, and not using protection makes it easier for HIV to enter the body and infect you. So, use one or more forms of protection to reduce your HIV risk."
            },
            Sti: {
              Value: 11,
              Explain:
                "You’re the TOP partner during VAGINAL sex, one of you has an STI, your partner has a detectable viral load, and you don’t use protection. Your partner's detectable viral load puts you at risk for HIV, and not using protection plus certain STIs makes it easier for HIV to enter the body and infect you. So, use one or more forms of protection to reduce your HIV risk."
            }
          }
        }
      }
    },
    Butt: {
      Condom: {
        Prep: {
          Uplus: {
            Null: {
              Value: 0.5,
              Explain:
                "You’re the TOP partner during ANAL sex, your partner is undetectable, you take PrEP, and you use condoms. This poses a LOW risk for HIV. Great job! You’re doing everything you can to protect your sexual health! Add or subtract some factors to see how your HIV risk changes."
            },
            Sti: {
              Value: 0.5,
              Explain:
                "You’re the TOP partner during ANAL sex, one of you has an STI, your partner is undetectable, you take PrEP, and you use condoms. This poses a LOW risk for HIV. Great job! You’re doing a lot to protect your sexual health! However, certain STIs can increase HIV risk. Subtract some factors to see how your HIV risk changes."
            }
          },
          Null: {
            Null: {
              Value: 0.5,
              Explain:
                "You’re the TOP partner during ANAL sex, your partner has a detectable viral load, you take PrEP, and you use condoms. This poses a LOW risk for HIV. Great job! Using two layers of protection reduces your risk for HIV by a lot, even when your partner has a detectable viral load!"
            },
            Sti: {
              Value: 1,
              Explain:
                "You’re the TOP partner during ANAL sex, one of you has an STI, your partner has a detectable viral load, you take PrEP, and you use condoms. Great job! Using two layers of protection reduces your risk for HIV by a lot, even when your partner has a detectable viral load! However, certain STIs can increase HIV risk."
            }
          }
        },
        Null: {
          Uplus: {
            Null: {
              Value: 0.5,
              Explain:
                "You’re the TOP partner during ANAL sex, your partner is undetectable, and you use condoms. This poses a LOW risk for HIV. Great job! Using condoms and having a partner who is undetectable reduces your risk for HIV by a lot! However, you can't control your partner's viral load. So, use more than one form of protection that IS under your control."
            },
            Sti: {
              Value: 0.5,
              Explain:
                "You’re the TOP partner during ANAL sex, one of you has an STI, your partner is undetectable, and you use condoms. This poses a LOW risk for HIV. Great job! Using condoms and having a partner who is undetectable reduces your risk for HIV by a lot! However, you can’t control your partner’s viral load, and certain STIs can increase HIV risk. So, use more than one form of protection that IS under your control."
            }
          },
          Null: {
            Null: {
              Value: 4,
              Explain:
                "You’re the TOP partner during ANAL sex, your partner has a detectable viral load, and you use condoms. Your partner’s detectable viral load puts you at risk for HIV. But, using condoms reduces your risk by a lot! Adding more forms of protection can further reduce your risk."
            },
            Sti: {
              Value: 11,
              Explain:
                "You’re the TOP partner during ANAL sex, one of you has an STI, your partner has a detectable viral load, and you use condoms. Your partner’s detectable viral load puts you at risk for HIV, and certain STIs can increase that risk. But, using condoms reduces your risk by a lot! Adding more forms of protection can further reduce your risk."
            }
          }
        }
      },
      Null: {
        Prep: {
          Uplus: {
            Null: {
              Value: 0.5,
              Explain:
                "You’re the TOP partner during ANAL sex, your partner is undetectable, and you take PrEP. This poses a LOW risk for HIV. Great job! Taking PrEP and having a partner who is undetectable reduces your risk for HIV by a lot! However, you can't control your partner's viral load. So, use more than one form of protection that IS under your control."
            },
            Sti: {
              Value: 0.5,
              Explain:
                "You’re the TOP partner during ANAL sex, one of you has an STI, your partner is undetectable, and you take PrEP. This poses a LOW risk for HIV. Great job! Taking PrEP and having a partner who is undetectable reduces your risk for HIV by a lot! However, you can’t control your partner’s viral load, and certain STIs can increase HIV risk. So, use more than one form of protection that IS under your control."
            }
          },
          Null: {
            Null: {
              Value: 1,
              Explain:
                "You’re the TOP partner during ANAL sex, your partner has a detectable viral load, and you take PrEP. Good job taking PrEP! However, your partner’s detectable viral load can still put you at risk for HIV if you miss doses of PrEP. So, use more than one form of protection just in case."
            },
            Sti: {
              Value: 2,
              Explain:
                "You’re the TOP partner during ANAL sex, one of you has an STI, your partner has a detectable viral load, and you take PrEP. Good job taking PrEP! Your partner’s detectable viral load puts you at risk for HIV, and certain STIs can increase that risk. But, taking PrEP reduces your risk by a lot! Adding more forms of protection can further reduce your risk."
            }
          }
        },
        Null: {
          Uplus: {
            Null: {
              Value: 0.5,
              Explain:
                "You’re the TOP partner during ANAL sex, and your partner is undetectable. This poses a LOW risk for HIV. The medication your HIV-positive partner takes helps keep HIV from spreading. But, you can’t control your partner’s viral load. So, use more than one form of protection that IS under your control. Add or subtract some factors to see how your HIV risk changes."
            },
            Sti: {
              Value: 1,
              Explain:
                "You’re the TOP partner during ANAL sex, one of you has an STI, and your partner is undetectable. The medication your HIV-positive partner takes helps keep HIV from spreading. But, you can't control your partner's viral load, and certain STIs can increase HIV risk. So, use more than one form of protection that IS under your control. Add or subtract some factors to see how your HIV risk changes."
            }
          },
          Null: {
            Null: {
              Value: 11,
              Explain:
                "You’re the TOP partner during ANAL sex, your partner has a detectable viral load, and you don’t use protection. Your partner's detectable viral load puts you at risk for HIV, and not using protection makes it easier for HIV to enter the body and infect you. So, use one or more forms of protection to reduce your HIV risk."
            },
            Sti: {
              Value: 29,
              Explain:
                "You’re the TOP partner during ANAL sex, one of you has an STI, your partner has a detectable viral load, and you don’t use protection. Your partner's detectable viral load puts you at risk for HIV, and not using protection plus certain STIs makes it easier for HIV to enter the body and infect you. So, use one or more forms of protection to reduce your HIV risk."
            }
          }
        }
      }
    }
  },
  Butt: {
    Hand: {
      Null: {
        Null: {
          Null: {
            Null: {
              Value: 0,
              Explain:
                "Getting your butt fingered poses a VERY LOW risk for HIV."
            }
          }
        }
      }
    },
    Mouth: {
      Null: {
        Null: {
          Null: {
            Null: {
              Value: 0,
              Explain:
                "Getting a rim job (oral sex on butt) poses a VERY LOW risk for HIV.  "
            }
          }
        }
      }
    },
    Penis: {
      Condom: {
        Prep: {
          Uplus: {
            Null: {
              Value: 0.5,
              Explain:
                "You’re the BOTTOM partner during ANAL sex, your partner is undetectable, you take PrEP, and you use condoms. This poses a LOW risk for HIV. Great job! You’re doing everything you can to protect your sexual health! Add or subtract some factors to see how your HIV risk changes."
            },
            Sti: {
              Value: 0.5,
              Explain:
                "You’re the BOTTOM partner during ANAL sex, one of you has an STI, your partner is undetectable, you take PrEP, and you use condoms.  This poses a LOW risk for HIV. Great job! You’re doing a lot to protect your sexual health! However, certain STIs can increase HIV risk. Subtract some factors to see how your HIV risk changes."
            }
          },
          Null: {
            Null: {
              Value: 3,
              Explain:
                "You’re the BOTTOM partner during ANAL sex, your partner has a detectable viral load, you take PrEP, and you use condoms. Great job! Using two layers of protection reduces your risk for HIV by a lot, even when your partner has a detectable viral load!"
            },
            Sti: {
              Value: 8,
              Explain:
                "You’re the BOTTOM partner during ANAL sex, one of you has an STI, your partner has a detectable viral load, you take PrEP, and you use condoms. Great job! Using two layers of protection reduces your risk for HIV by a lot, even when your partner has a detectable viral load! However, certain STIs can increase HIV risk."
            }
          }
        },
        Null: {
          Uplus: {
            Null: {
              Value: 2,
              Explain:
                "You’re the BOTTOM partner during ANAL sex, your partner is undetectable, and you use condoms. Great job! Using condoms and having a partner who is undetectable reduces your risk for HIV by a lot! However, you can't control your partner's viral load. So, use more than one form of protection that IS under your control."
            },
            Sti: {
              Value: 4,
              Explain:
                "You’re the BOTTOM partner during ANAL sex, one of you has an STI, your partner is undetectable, and you use condoms. Great job! Using condoms and having a partner who is undetectable reduces your risk for HIV by a lot! However, you can’t control your partner’s viral load, and certain STIs can increase HIV risk. So, use more than one form of protection that IS under your control."
            }
          },
          Null: {
            Null: {
              Value: 39,
              Explain:
                "You’re the BOTTOM partner during ANAL sex, your partner has a detectable viral load, and you use condoms. Your partner’s detectable viral load puts you at risk for HIV. But, using condoms reduces your risk by a lot! Adding more forms of protection can further reduce your risk."
            },
            Sti: {
              Value: 102,
              Explain:
                "You’re the BOTTOM partner during ANAL sex, one of you has an STI, your partner has a detectable viral load, and you use condoms. Good job using condoms! However, this still poses a HIGH risk for HIV because of your partner’s detectable viral load, and certain STIs can inrease that risk. Adding more forms of protection can reduce your risk."
            }
          }
        }
      },
      Null: {
        Prep: {
          Uplus: {
            Null: {
              Value: 0.5,
              Explain:
                "You’re the BOTTOM partner during ANAL sex, your partner is undetectable, and you take PrEP. This poses a LOW risk for HIV. Great job! Taking PrEP and having a partner who is undetectable reduces your risk for HIV by a lot! However, you can't control your partner's viral load. So, use more than one form of protection that IS under your control."
            },
            Sti: {
              Value: 1,
              Explain:
                "You’re the BOTTOM partner during ANAL sex, one of you has an STI, your partner is undetectable, and you take PrEP. Great job! Taking PrEP and having a partner who is undetectable reduces your risk for HIV by a lot! However, you can’t control your partner’s viral load, and certain STIs can increase HIV risk. So, use more than one form of protection that IS under your control."
            }
          },
          Null: {
            Null: {
              Value: 11,
              Explain:
                "You’re the BOTTOM partner during ANAL sex, your partner has a detectable viral load, and you take PrEP. Good job taking PrEP! However, your partner’s detectable viral load can still put you at risk for HIV if you miss doses of PrEP. So, use more than one form of protection just in case."
            },
            Sti: {
              Value: 29,
              Explain:
                "You’re the BOTTOM partner during ANAL sex, one of you has an STI, your partner has a detectable viral load, and you take PrEP. Good job taking PrEP! Your partner’s detectable viral load puts you at risk for HIV, and certain STIs can increase that risk. But, taking PrEP reduces your risk by a lot! Adding more forms of protection can further reduce your risk."
            }
          }
        },
        Null: {
          Uplus: {
            Null: {
              Value: 6,
              Explain:
                "You’re the BOTTOM partner during ANAL sex, and your partner is undetectable. The medication your HIV-positive partner takes helps keep HIV from spreading. But, you can’t control your partner’s viral load. So, use more than one form of protection that IS under your control. Add or subtract some factors to see how your HIV risk changes."
            },
            Sti: {
              Value: 15,
              Explain:
                "You’re the BOTTOM partner during ANAL sex, one of you has an STI, and your partner is undetectable. This puts you at risk for HIV. The medication your HIV-positive partner takes helps keep HIV from spreading. But, you can't control your partner's viral load, and certain STIs can increase HIV risk. So, use more than one form of protection that IS under your control. Add or subtract some factors to see how your HIV risk changes."
            }
          },
          Null: {
            Null: {
              Value: 138,
              Explain:
                "You’re the BOTTOM partner during ANAL sex, your partner has a detectable viral load, and you don’t use protection. This poses a HIGH risk for HIV because of your partner's detectable viral load, and not using protection makes it easier for HIV to enter the body and infect you. So, use more than one form of protection to reduce your HIV risk."
            },
            Sti: {
              Value: 366,
              Explain:
                "You’re the BOTTOM partner during ANAL sex, one of you has an STI, your partner has a detectable viral load, and you don’t use protection. This poses one of the HIGHEST risks for HIV because of your partner's detectable viral load, and not using protection plus certain STIs makes it easier for HIV to enter the body and infect you. So, use one or more forms of protection to reduce your HIV risk."
            }
          }
        }
      }
    },
    Vagina: {
      Null: {
        Null: {
          Null: {
            Null: {
              Value: 0,
              Explain:
                "Extra points for being creative during sex. Rubbing these two parts together does not put you at risk for HIV."
            }
          }
        }
      }
    },
    Butt: {
      Null: {
        Null: {
          Null: {
            Null: {
              Value: 0,
              Explain:
                "Bumping butts with your partner does not put you at risk for HIV."
            }
          }
        }
      }
    }
  }
};
