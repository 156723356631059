import RailsAjax from "../shared/rails-ajax";

let question = document.querySelector(".true-false-question");
let answers = Array.from(document.querySelectorAll(".true-false-answer"));
let responseAlert = document.getElementById("true-false-alert");

answers.forEach(function (answer) {
  let marker = answer.getElementsByClassName("answer-marker")[0];
  let placeholder = answer.getElementsByClassName("placeholder-marker")[0];

  function handleResponse() {
    if (
      answer.dataset.correct == "true" &&
      answer.classList.contains("border-success")
    ) {
      answer.classList.remove("border-success", "text-success");
      marker.classList.add("invisible");
      responseAlert.innerHTML = "";

      return;
    } else if (answer.classList.contains("border-danger")) {
      answer.classList.remove("border-danger", "text-danger");
      marker.classList.add("invisible");
      responseAlert.innerHTML = "";

      return;
    }

    let xhr = RailsAjax.post(question.dataset.apiUrl, {
      participant_events_true_false_response: {
        content_true_false_question_id: question.dataset.id,
        answer_text: answer.dataset.text
      }
    });

    xhr.addEventListener("load", function (event) {
      answer.classList.add("text-white");

      if (answer.dataset.correct == "true") {
        answer.classList.add("border-success", "text-success");
        marker.innerHTML =
          '<i class="fas fa-lg fa-check align-self-center"></i>';
        marker.classList.remove("invisible");
        responseAlert.innerHTML =
          '<div class="alert alert-success mt-4" role="alert">' +
          answer.dataset.explanation +
          "</div>";
      } else {
        answer.classList.add("border-danger", "text-danger");
        marker.innerHTML =
          '<i class="fas fa-lg fa-times align-self-center"></i>';
        marker.classList.remove("invisible");
        placeholder.innerHTML =
          '<i class="fas fa-lg fa-times align-self-center"></i>';
        responseAlert.innerHTML =
          '<div class="alert alert-danger mt-4" role="alert">' +
          answer.dataset.explanation +
          "</div>";
      }
    });
  }

  answer.addEventListener("click", handleResponse, false);
});
