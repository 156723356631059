import RailsAjax from "../../shared/rails-ajax";

if ($("#stiCalc").length) {
  var you, partner;
  you = partner = "Null";
  var factors = {
    Condom: "Null",
    Prep: "Null",
    Uplus: "Null"
  };
  var xtrmSize = 65;
  var regSize = 35;
  var limit = 1.5;
  var max = 2;
  var invisibleArrow = {
    opacity: "0",
    left: "50%",
    right: "50%"
  };

  function sliderCSS(opacity, leftPos) {
    return {
      opacity: opacity,
      left: "calc(" + leftPos + "% - .25em)"
    };
  }

  function arrowCSS(leftPos, rightPos, smaller) {
    return {
      opacity: ".75",
      left: "calc(" + leftPos + " + .25em" + (smaller ? " + 1em" : "") + ")",
      right:
        "calc(100% - (" +
        rightPos +
        " + .25em)" +
        (smaller ? "" : " + 1em") +
        ")"
    };
  }

  $(".youBtn").click(function () {
    $(this).parent().siblings().children(".youBtn").removeClass("clicked");
    $(this).addClass("clicked");
    you = $(this).data("label");
    setSlider();
    sendSelection("you_" + $(this).data("label"));
  });

  $(".partnerBtn").click(function () {
    $(this).parent().siblings().children(".partnerBtn").removeClass("clicked");
    $(this).addClass("clicked");
    partner = $(this).data("label");
    setSlider();
    sendSelection("partner_" + $(this).data("label"));
  });

  $(".factorBtn").click(function () {
    if ($(this).hasClass("clicked")) {
      $(this).removeClass("clicked");

      if (!$(".factorBtn.clicked").length) {
        $("#factorSlider").css(sliderCSS(0, 0));
      }

      factors[this.dataset.label] = "Null";
    } else {
      $(this).addClass("clicked");
      factors[this.dataset.label] = this.dataset.label;
    }

    setSlider();
    sendSelection($(this).data("label"));
  });

  $(".sexBtn").click(function () {
    $(".sexBtn.clicked").removeClass("clicked");
    $(this).addClass("clicked");

    if ($("#man").hasClass("clicked")) {
      $("#sex img")
        .attr({
          src: $("#man").data("img-url"),
          alt: "Penis"
        })
        .parent()
        .data("label", "Penis");
    } else {
      $("#sex img")
        .attr({
          src: $("#woman").data("img-url"),
          alt: "Vagina"
        })
        .parent()
        .data("label", "Vagina");
    }

    if ($("#sex").hasClass("clicked")) {
      partner = $("#sex img").data("label");
      setSlider();
    }

    sendSelection($(this).data("label"));
  });

  function setSlider() {
    //returns undefined if any items are undefined
    var factorData = (((((sti_risk_data || {})[you] || {})[partner] || {})[
      factors["Condom"]
    ] || {})[factors["Prep"]] || {})[factors["Uplus"]];
    var partnerData = (((((sti_risk_data || {})[you] || {})[partner] || {})[
      "Null"
    ] || {})["Null"] || {})["Null"];
    if (
      typeof factorData === "undefined" &&
      typeof partnerData === "undefined"
    ) {
      $(".bars").removeClass("fullbar");
      $("#factorSlider, #partnerSlider").css(sliderCSS(0, 0));
      $("#arrow").css(invisibleArrow);
      $("#explanation").text("Invalid combination, try again.");
    } else if (typeof factorData === "undefined") {
      $("#factorSlider").css(sliderCSS(0));
      $("#arrow").css(invisibleArrow);
      $("#explanation").text(partnerData["Explain"]);
      partnerData["Value"] < limit
        ? $(".bars").removeClass("fullbar")
        : $(".bars").addClass("fullbar");

      var pDataVal = $(".bars").hasClass("fullbar")
        ? (partnerData["Value"] / max) * xtrmSize + regSize
        : (partnerData["Value"] / limit) * 100;
      $("#partnerSlider").css(sliderCSS(0.75, pDataVal));
    } else {
      $("#explanation").text(factorData["Explain"]);

      factorData["Value"] >= limit || partnerData["Value"] >= limit
        ? $(".bars").addClass("fullbar")
        : $(".bars").removeClass("fullbar");
      var pDataVal = $(".bars").hasClass("fullbar")
        ? partnerData["Value"] < limit
          ? (partnerData["Value"] / limit) * regSize
          : (partnerData["Value"] / max) * xtrmSize + regSize
        : (partnerData["Value"] / limit) * 100;
      $("#partnerSlider").css(sliderCSS(0.75, pDataVal));

      if ($(".factorBtn.clicked").length) {
        var fDataVal = $(".bars").hasClass("fullbar")
          ? factorData["Value"] < limit
            ? (factorData["Value"] / limit) * regSize
            : (factorData["Value"] / max) * xtrmSize + regSize
          : (factorData["Value"] / limit) * 100;
        $("#factorSlider").css(sliderCSS(0.75, fDataVal));

        if (fDataVal == pDataVal) {
          $("#arrow").css(invisibleArrow);
        } else {
          arrowCalc(fDataVal, pDataVal);
        }
      } else {
        $("#arrow").css(invisibleArrow);
      }
    }

    $("#explanation").html(
      $("#explanation").text().replace(/.+?\./, "<b>$&</b><br>")
    );
  }

  function arrowCalc(fDataVal, pDataVal) {
    var factorPos = $("#factorSlider")[0].style.left;
    var partnerPos = $("#partnerSlider")[0].style.left;

    if (fDataVal < pDataVal) {
      $("#arrow").removeClass("right").addClass("left");
      $("#arrow").css(arrowCSS(factorPos, partnerPos, 1));
    } else {
      $("#arrow").removeClass("left").addClass("right");
      $("#arrow").css(arrowCSS(partnerPos, factorPos, 0));
    }
  }

  function sendSelection(item) {
    RailsAjax.post(
      "/participants/participant_events/sti_risk_calculator_selections",
      {
        sti_risk_calculator_selection: {
          item: item
        }
      }
    );
  }
}
