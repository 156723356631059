function showElement(elementId) {
  let element = document.getElementById(elementId);

  element.classList.add("show");
  element.querySelectorAll("input").forEach((input) => {
    input.disabled = false;
  });
}

function hideElement(elementId) {
  let element = document.getElementById(elementId);

  element.classList.remove("show");
  element.querySelectorAll("input").forEach((input) => {
    input.disabled = true;
  });
}

// Branching answers reveal or hide questions that follow based on the one chosen
let branchingAnswers = Array.from(
  document.querySelectorAll(".branching-answer")
);

branchingAnswers.forEach((answer) => {
  function handleBranching() {
    if (answer.checked && answer.value == answer.dataset.showValue) {
      showElement(answer.dataset.showId);
    } else if (answer.checked && answer.value != answer.dataset.showValue) {
      hideElement(answer.dataset.showId);
    }
  }

  answer.addEventListener("change", handleBranching, true);
});

// Feedback answers display feedback dependent on which answer is chosen
let feedbackAnswers = Array.from(document.querySelectorAll(".feedback-answer"));

feedbackAnswers.forEach((answer) => {
  function feedbackIdFromAnswer(value) {
    let map = JSON.parse(answer.dataset.feedbackMap);

    return map.find((mapping) => {
      return mapping.label == value;
    }).feedback_id;
  }

  function handleFeedback() {
    document.querySelectorAll(".answer-feedback").forEach((feedback) => {
      feedback.classList.remove("show");
    });

    if (answer.checked) {
      showElement(feedbackIdFromAnswer(answer.value));
    }
  }

  answer.addEventListener("change", handleFeedback, true);
});

// Branching answer pairs reveal or hide questions that follow based on a combination of two chosen
let branchingAnswerPairs = Array.from(
  document.querySelectorAll(".branching-answer-pair")
);

branchingAnswerPairs.forEach((answerPair) => {
  let inputs = answerPair.querySelectorAll("input");
  let showConditions = JSON.parse(answerPair.dataset.showConditions);

  function handleResponse() {
    let inputResponses = [];
    inputs.forEach((input) => inputResponses.push(input));
    inputResponses = inputResponses
      .filter((input) => input.checked)
      .map((input) => input.value);

    if (inputResponses.length != 2) {
      return;
    }

    hideElement(answerPair.dataset.showId);

    showConditions.forEach((conditions) => {
      if (
        inputResponses[0] == conditions[0] &&
        inputResponses[1] == conditions[1]
      ) {
        showElement(answerPair.dataset.showId);
      }
    });
  }

  inputs.forEach((input) => {
    input.addEventListener("change", handleResponse, true);
  });
});

// Required checkboxes must have at least one box checked
let requiredCheckboxes = Array.from(
  document.querySelectorAll(".required-checkboxes")
);

requiredCheckboxes.forEach((checkbox) => {
  checkbox.addEventListener("click", (event) => {
    let checkboxGroupClass = event.target.dataset.groupClass;
    let groupCheckboxes = document.querySelectorAll("." + checkboxGroupClass);

    let atLeastOneChecked = Array.prototype.some.call(
      groupCheckboxes,
      (groupCheckbox) => {
        return groupCheckbox.checked;
      }
    );

    groupCheckboxes.forEach((groupCheckbox) => {
      groupCheckbox.required = !atLeastOneChecked;
    });
  });
});

// the following code handles the multistep follow-up navigation
import Rails from "@rails/ujs";

var currentFieldset, nextFieldset, previousFieldset; //fieldsets
var left, opacity, scale; //fieldset properties which we will animate
var animating; //flag to prevent quick multi-click glitches

$("button.multistep.nav-next").click(function () {
  let form = document.querySelector("form.multistep");
  let inputFieldset = document.getElementById("input-fieldset");

  if ($(inputFieldset).is(":visible") && this.dataset.submit == "true") {
    if (form.reportValidity()) {
      Rails.fire(form, "submit");
      navNext();
    }
  } else {
    navNext();
  }
});

function navNext() {
  if (animating) return false;
  animating = true;

  currentFieldset = $(".form-step:visible");
  nextFieldset = currentFieldset.next();

  if (nextFieldset.length == 0) {
    window.location.href =
      window.location.origin +
      document.querySelector("button.multistep.nav-next").dataset.nextPath;

    return;
  }

  $("#progressbar li")
    .eq($(".form-step").index(currentFieldset))
    .removeClass("active");
  //activate next step on progressbar using the index of nextFieldset
  $("#progressbar li")
    .eq($(".form-step").index(nextFieldset))
    .addClass("active")
    .addClass("activated");

  //show the next fieldset
  nextFieldset.show();
  //hide the current fieldset with style
  currentFieldset.animate(
    { opacity: 0 },
    {
      step: function (now, mx) {
        //as the opacity of currentFieldset reduces to 0 - stored in "now"
        //1. scale currentFieldset down to 80%
        scale = 1 - (1 - now) * 0.2;
        //3. increase opacity of nextFieldset to 1 as it moves in
        opacity = 1 - now;
        currentFieldset.css({
          transform: "scale(" + scale + ")",
          position: "absolute"
        });
        nextFieldset.css({ left: left, opacity: opacity });
      },
      duration: 800,
      complete: function () {
        currentFieldset.hide();
        animating = false;
      },
      //this comes from the custom easing plugin
      easing: "swing"
    }
  );
}

$(".previous").click(function () {
  if (animating) return false;
  animating = true;

  currentFieldset = $(this).parent();
  previousFieldset = $(this).parent().prev();

  //de-activate current step on progressbar
  $("#progressbar li")
    .eq($(".form-step").index(currentFieldset))
    .removeClass("active");

  //show the previous fieldset
  previousFieldset.show();
  //hide the current fieldset with style
  currentFieldset.animate(
    { opacity: 0 },
    {
      step: function (now, mx) {
        //as the opacity of currentFieldset reduces to 0 - stored in "now"
        //1. scale previousFieldset from 80% to 100%
        scale = 0.8 + (1 - now) * 0.2;
        //2. take currentFieldset to the right(50%) - from 0%
        left = (1 - now) * 50 + "%";
        //3. increase opacity of previousFieldset to 1 as it moves in
        opacity = 1 - now;
        currentFieldset.css({ left: left });
        previousFieldset.css({
          transform: "scale(" + scale + ")",
          opacity: opacity
        });
      },
      duration: 400,
      complete: function () {
        currentFieldset.hide();
        animating = false;
      },
      //this comes from the custom easing plugin
      easing: "swing"
    }
  );
});
