// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
Rails.start();
require("@rails/activestorage").start();

import "./sentry-init.js";

import JQuery from "jquery";
window.$ = JQuery;
import "bootstrap";
import "bootstrap/scss/bootstrap";
import "./navigation.scss";
import "./search-form.scss";
import "./style.scss";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { RDAudioEventCapture } from "raindrops";
RDAudioEventCapture.initialize(function (audioForm) {
  Rails.fire(audioForm, "submit");
});

require.context("../images", true);

function importAll(r) {
  r.keys().forEach(r);
}

importAll(require.context("./participants/", true, /.(js|scss)$/));
importAll(require.context("./visitors/", true, /.(js|scss)$/));
