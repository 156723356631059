import Player from "@vimeo/player";
import RailsAjax from "../shared/rails-ajax";
import * as Sentry from "@sentry/browser";

let videos = Array.from(document.querySelectorAll(".vimeo-video"));

function sendVideoEvent(compositeVideoId, kind, seconds) {
  RailsAjax.post("/participants/video_actions.json", {
    video_action: {
      composite_video_id: compositeVideoId,
      kind: kind,
      current_playback_time_in_s: seconds
    }
  });
}

videos.forEach(function (video) {
  let player = new Player(video);
  let compositeVideoId = video.dataset["compositeVideoId"];

  player
    .ready()
    .then(function () {
      player.on("play", function (status) {
        sendVideoEvent(compositeVideoId, "play", status.seconds);
      });
      player.on("pause", function (status) {
        sendVideoEvent(compositeVideoId, "pause", status.seconds);
      });
      player.on("ended", function (status) {
        sendVideoEvent(compositeVideoId, "ended", status.seconds);
        exitFullscreen();
      });
    })
    .catch(function (e) {
      Sentry.captureMessage(e.message + ", source: " + player.element.src);
    });
});

function exitFullscreen() {
  if (
    document.fullscreenElement ||
    document.mozFullScreenElement ||
    document.webkitFullscreenElement ||
    document.msFullscreenElement
  ) {
    // If there's currently an element fullscreen, exit fullscreen
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  }
}
