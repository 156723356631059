import RailsAjax from "../../shared/rails-ajax";

let submitWithDisclaimers = Array.from(
  document.querySelectorAll(".submit-path-with-disclaimer")
);

let modal = document.getElementById("modal-path-disclaimer-container");

function addParamsToForm(form, params) {
  JSON.parse(params).forEach(function (pair) {
    form.innerHTML =
      form.innerHTML +
      '<input type="hidden" name="' +
      pair[0] +
      '" value="' +
      pair[1] +
      '">';
  });
}

function createEmailDisclaimerResponse(accepted, url, name) {
  console.log(name);
  RailsAjax.post(url, {
    email_disclaimer_response: {
      accepted: accepted,
      email_prompt: name
    }
  });
}

submitWithDisclaimers.forEach(function (disclaimerButton) {
  disclaimerButton.addEventListener("click", function () {
    modal.style.display = "block";
    let form = document.querySelector(".button_to");
    form.action = this.dataset.path;

    if (this.dataset.params != null) {
      addParamsToForm(form, this.dataset.params);
    }

    document.getElementById("modal-path-disclaimer-title").innerHTML =
      this.dataset.modalTitle;
    document.getElementById("path-disclaimer-accept").value =
      this.dataset.submitWith;
    document.getElementById("path-disclaimer-decline").innerHTML =
      this.dataset.cancelWith;
    document.getElementById("path-disclaimer-text").innerHTML =
      this.dataset.disclaimerText;
  });
});

const disclaimerDecline = document.getElementById("path-disclaimer-decline");

if (disclaimerDecline) {
  disclaimerDecline.addEventListener("click", function () {
    modal.style.display = "none";
    createEmailDisclaimerResponse(
      false,
      modal.dataset.email_disclaimer_response_post_url,
      modal.dataset.controller_name
    );
  });
}

const disclaimerClose = document.getElementById("path-disclaimer-modal-close");

if (disclaimerClose) {
  disclaimerClose.addEventListener("click", function () {
    modal.style.display = "none";
    createEmailDisclaimerResponse(
      false,
      modal.dataset.email_disclaimer_response_post_url,
      modal.dataset.controller_name
    );
  });
}

const disclaimerAccept = document.getElementById("path-disclaimer-accept");

if (disclaimerAccept) {
  disclaimerAccept.addEventListener("click", function () {
    createEmailDisclaimerResponse(
      true,
      modal.dataset.email_disclaimer_response_post_url,
      modal.dataset.controller_name
    );
  });
}
