// the following code handles the multistep navigation
import Rails from "@rails/ujs";
import RailsAjax from "../shared/rails-ajax";

var currentFieldset, nextFieldset, previousFieldset; //fieldsets
var left, opacity, scale; //fieldset properties which we will animate
var animating; //flag to prevent quick multi-click glitches

let modal = document.getElementById("iat-incomplete");

let cancelButton = document.getElementById("iat-incomplete-cancel");
let form = document.querySelector("form.iat-multistep");

function sendPageViewEvent(pageNumber) {
  RailsAjax.post(form.dataset.apiUrl, {
    iat_quiz_page_view: {
      page_number: pageNumber
    }
  });
}

if (cancelButton != null) {
  cancelButton.addEventListener("click", function () {
    modal.style.display = "none";
  });

  sendPageViewEvent("1");
}

$("button.iat-multistep.nav-next").click(function () {
  Rails.fire(form, "submit");

  let incomplete = false;
  Array.from(
    document.querySelectorAll("#show-modal-unless-complete select")
  ).forEach((pseudoRequired) => {
    if (pseudoRequired.value == "") {
      incomplete = true;
    }
  });

  if (incomplete) {
    modal.style.display = "block";
  } else {
    $(".form-step:visible select").prop("disabled", true);
    $(".form-step:visible textarea").prop("disabled", true);
    navNext();
  }
});

function navNext() {
  if (animating) return false;
  animating = true;

  currentFieldset = $(".form-step:visible");
  nextFieldset = currentFieldset.next();
  nextFieldset.css("position", "relative");

  if (nextFieldset.length == 0) {
    window.location.href =
      window.location.origin +
      document.querySelector("button.iat-multistep.nav-next").dataset.nextPath;

    return;
  }

  $("#progressbar li")
    .eq($(".form-step").index(currentFieldset))
    .removeClass("active");
  //activate next step on progressbar using the index of nextFieldset
  $("#progressbar li")
    .eq($(".form-step").index(nextFieldset))
    .addClass("active")
    .addClass("activated");

  //show the next fieldset
  nextFieldset.show();
  //hide the current fieldset with style
  currentFieldset.animate(
    { opacity: 0 },
    {
      step: function (now, mx) {
        //as the opacity of currentFieldset reduces to 0 - stored in "now"
        //1. scale currentFieldset down to 80%
        scale = 1 - (1 - now) * 0.2;
        //3. increase opacity of nextFieldset to 1 as it moves in
        opacity = 1 - now;
        currentFieldset.css({
          transform: "scale(" + scale + ")",
          position: "absolute"
        });
        nextFieldset.css({ left: left, opacity: opacity });
      },
      duration: 400,
      complete: function () {
        currentFieldset.hide();
        animating = false;
      },
      //this comes from the custom easing plugin
      easing: "swing"
    }
  );
  sendPageViewEvent($(".form-step").index(nextFieldset) + 1);
}

$("button.iat-multistep.nav-back").click(function () {
  if (animating) return false;
  animating = true;

  currentFieldset = $(".form-step:visible");
  previousFieldset = currentFieldset.prev();
  previousFieldset.css("position", "relative");

  //de-activate current step on progressbar
  $("#progressbar li")
    .eq($(".form-step").index(currentFieldset))
    .removeClass("activated");

  //show the previous fieldset
  previousFieldset.show();
  //hide the current fieldset with style
  currentFieldset.animate(
    { opacity: 0 },
    {
      step: function (now, mx) {
        //as the opacity of currentFieldset reduces to 0 - stored in "now"
        //1. scale previousFieldset from 80% to 100%
        scale = 0.8 + (1 - now) * 0.2;
        //2. take currentFieldset to the right(50%) - from 0%
        left = (1 - now) * 50 + "%";
        //3. increase opacity of previousFieldset to 1 as it moves in
        opacity = 1 - now;
        currentFieldset.css({ left: left, position: "absolute" });
        previousFieldset.css({
          transform: "scale(" + scale + ")",
          opacity: opacity
        });
      },
      duration: 800,
      complete: function () {
        currentFieldset.hide();
        animating = false;
        left = 0;
      },
      //this comes from the custom easing plugin
      easing: "swing"
    }
  );
  sendPageViewEvent($(".form-step").index(previousFieldset) + 1);
});
