import RailsAjax from "../shared/rails-ajax";

const CHECK_PERIOD_IN_MS = 15000;
const WARNING_MARGIN_IN_MS = 60000;

document.addEventListener("DOMContentLoaded", function () {
  let timeoutTargetPath =
    document.querySelector("body").dataset.timeoutTargetPath;
  let timeoutWarningElement = document.getElementById("timeout-warning");

  if (timeoutTargetPath == null || timeoutWarningElement == null) {
    return;
  }
  timeoutWarningElement
    .querySelector(".keep-alive")
    .addEventListener("click", keepAuthenticated);
  timeoutWarningElement
    .querySelector(".logout")
    .addEventListener("click", logout);

  setTimeout(checkForAuthTimeout, CHECK_PERIOD_IN_MS);

  function checkForAuthTimeout() {
    fetch(timeoutTargetPath, { headers: { "devise.skip_trackable": true } })
      .then(function (response) {
        return response.json();
      })
      .then(warnOfAuthTimeout)
      .catch(logout);
  }

  function warnOfAuthTimeout(result) {
    if (result.timeout_at != null) {
      let timeoutAt = new Date(result.timeout_at);
      let timeUntilTimeout = timeoutAt - new Date();

      if (
        timeUntilTimeout < WARNING_MARGIN_IN_MS &&
        $(".modal-open #timeout-warning").length == 0
      ) {
        $("#timeout-warning-trigger").trigger("click");
      }

      if (timeUntilTimeout >= CHECK_PERIOD_IN_MS) {
        setTimeout(checkForAuthTimeout, CHECK_PERIOD_IN_MS);
      } else {
        setTimeout(checkForAuthTimeout, 1000);
      }
    }
  }

  function keepAuthenticated() {
    let xhr = RailsAjax.post(timeoutTargetPath, {});
    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        if ($(".modal-open #timeout-warning").length == 1) {
          $("#timeout-warning-trigger").trigger("click");
        }
      }
    };
  }

  function logout() {
    let xhr = RailsAjax.delete(timeoutTargetPath, {});
    xhr.onload = () => {
      if (xhr.status == 0 || (xhr.status >= 200 && xhr.status < 300)) {
        reloadPage();
      }
    };
  }

  function reloadPage() {
    location.reload(true);
  }
});
